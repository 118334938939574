var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.messageList),function(con,i){return _c('div',{key:i},[(con.userType == 'system')?_c('div',{staticClass:"px-2 mb-3"},[(con.systemType == _vm.systemType.TicketUpdated)?_c('div',[_c('div',{staticClass:"separator ticket"},[_c('span',{staticClass:"font-italic mr-1",on:{"click":function($event){return _vm.showFormTicket(con.ticketUrl)}}},[_vm._v(_vm._s(con.message))])])]):(con.adminName.trim() == '')?_c('div',[_c('div',{staticClass:"separator"},[_c('span',{staticClass:"font-italic mr-1"},[_vm._v(_vm._s(con.message))])])]):(con.systemType == _vm.systemType.AdminLeaveGroupMention)?_c('div',[_c('div',{staticClass:"separator"},[_c('span',{staticClass:"admin-name mr-1"},[_vm._v(_vm._s(con.adminName))]),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(con.message))])])]):(con.systemType == _vm.systemType.AssignTo)?_c('div',[_c('div',{staticClass:"separator"},[_c('span',{staticClass:"font-italic mr-1"},[_vm._v(_vm._s(con.message)+" "),_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.toAdminName))]),_vm._v(" by")]),_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.adminName))])])]):(
          con.systemType == _vm.systemType.MoveToPending ||
            con.systemType == _vm.systemType.MoveToClose ||
            con.systemType == _vm.systemType.MoveToCase
        )?_c('div',[_c('div',{staticClass:"separator"},[_c('span',{staticClass:"font-italic mr-1"},[_vm._v(_vm._s(con.message))]),_c('span',{staticClass:"mx-1 font-italic"},[_vm._v("by")]),_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.adminName))])])]):(con.topicId)?_c('div',[_c('div',{staticClass:"separator"},[_c('span',{staticClass:"font-italic mr-1"},[_vm._v(_vm._s(con.message))]),_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.topicName))]),_c('span',{staticClass:"mx-1 font-italic"},[_vm._v("by")]),_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.adminName))])])]):(con.toAdminName && con.toAdminName.trim())?_c('div',[_c('div',{staticClass:"separator"},[_c('span',{staticClass:"font-italic mr-1"},[_vm._v(_vm._s(con.message))]),(con.toAdminName != con.adminName)?_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.toAdminName))]):_vm._e(),(con.toAdminName != con.adminName)?_c('span',{staticClass:"mx-1 font-italic"},[_vm._v("by")]):_vm._e(),_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.adminName))])])]):_c('div',{staticClass:"separator"},[_vm._v(" "+_vm._s(con.message)+" "),_c('span',{staticClass:"mx-1"},[_vm._v("by")]),_c('span',{staticClass:"admin-name"},[_vm._v(_vm._s(con.adminName))])]),_c('div',{staticClass:"end-chat-time text-center"},[(con.isWaitSend)?_c('b-spinner',{staticClass:"sending-chat",attrs:{"label":"sending..."}}):(con.isSendFail)?_c('div',{staticClass:"send-fail justify-content-center"},[_vm._v(" ส่งไม่สำเร็จ "),_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}})],1):_c('ChatDateTime',{attrs:{"date":con.createdTime}})],1)]):_vm._e(),(con.userType == 'admin')?_c('div',{staticClass:"d-flex align-items-end flex-column justify-content-end mb-4"},[_c('div',{class:[
          con.messageType == 4
            ? 'msg_cotainer_send_img'
            : con.isPrivateChat
            ? 'msg_cotainer_send_private'
            : 'msg_cotainer_send'
        ]},[(
            con.messageType == 1 ||
              con.messageType == 21 ||
              con.messageType == 16 ||
              con.messageType == 22
          )?_c('span',{staticClass:"text-nextline",domProps:{"innerHTML":_vm._s(con.message)}}):_vm._e(),(con.messageType == 4)?_c('div',[_c('div',{staticClass:"img_upload",on:{"click":function($event){return _vm.openModalImage(con.filePath)}}},[_c('div',{staticClass:"img_upload-display",style:({
                backgroundImage: 'url(' + con.filePath + ')'
              })})])]):_vm._e(),(
            con.messageType != 1 &&
              con.messageType != 4 &&
              con.messageType != 21 &&
              con.messageType != 16 &&
              con.messageType != 22
          )?_c('div',[_c('ChatBoxWidget',{attrs:{"con":con}})],1):_vm._e()]),_c('span',{staticClass:"msg_time_send text-nowrap"},[(con.isWaitSend)?_c('b-spinner',{staticClass:"sending-chat",attrs:{"label":"sending..."}}):(con.isSendFail)?_c('div',{staticClass:"send-fail"},[_vm._v(" ส่งไม่สำเร็จ "),_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}})],1):_c('ChatDateTime',{attrs:{"date":con.createdTime}}),(con.adminName)?_c('span',[_vm._v(" ("),_c('span',[_vm._v(_vm._s(con.adminName))]),_vm._v(") ")]):_vm._e()],1)]):(con.userType == 'user')?_c('div',{staticClass:"d-flex justify-content-start mb-4 px-2"},[_c('div',{staticClass:"img_cont_msg"},[(_vm.otherInfo.imageUrl && _vm.otherInfo.imageUrl.trim())?_c('div',{staticClass:"rounded-circle user_img_msg"},[_c('div',{staticClass:"rounded-circle user_img_display",style:({
              backgroundImage: 'url(' + _vm.otherInfo.imageUrl + ')'
            })})]):_c('font-awesome-icon',{staticClass:"rounded-circle user_img_msg p-1",attrs:{"icon":['fas', 'user']}})],1),_c('div',{class:[
          con.messageType == 4 || con.messageType == 2
            ? 'msg_cotainer_img'
            : 'msg_cotainer'
        ]},[(
            con.messageType == 1 ||
              con.messageType == 21 ||
              con.messageType == 16 ||
              con.messageType == 22
          )?_c('span',{staticClass:"text-nextline",domProps:{"innerHTML":_vm._s(con.message)}}):_vm._e(),(con.messageType == 4)?_c('div',{staticClass:"img_upload"},[_c('div',{staticClass:"img_upload-display",style:({
              backgroundImage: 'url(' + con.filePath + ')'
            }),on:{"click":function($event){return _vm.openModalImage(con.filePath)}}})]):_vm._e(),(
            con.messageType != 1 &&
              con.messageType != 4 &&
              con.messageType != 21 &&
              con.messageType != 16 &&
              con.messageType != 22
          )?_c('div',[_c('ChatBoxWidget',{attrs:{"con":con}})],1):_vm._e(),_c('span',{staticClass:"msg_time text-nowrap"},[_c('ChatDateTime',{attrs:{"date":con.createdTime}})],1)])]):_vm._e()])}),_c('ChatBoxModalImage',{ref:"ChatBoxModalImage",attrs:{"path":_vm.path}}),_c('ModalTicketIframe',{ref:"ModalTicketIframe"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }