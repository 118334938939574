<template>
  <div :class="['warp-history', { show: isShow }]" @click="hide">
    <b-sidebar
      id="sidebar-filter-history"
      :backdrop-variant="variant"
      backdrop
      shadow
      visible
      no-header
      right
    >
      <div class="sidepanel" @click.stop="">
        <div
          class="sticky-top d-flex justify-content-between align-items-center px-3"
        >
          <span class="title-side">Chat History</span>
          <b-button
            variant="import"
            class="w-auto ml-auto"
            @click="importFileLine"
          >
            <font-awesome-icon icon="file-import" class="bg-icon" />
            History
          </b-button>
          <a href="javascripxt:void(0)" class="closebtn" @click="hide">×</a>
        </div>
        <div class="filter-form border-top pt-2">
          <div class="px-3">
            <div class="position-relative">
              <b-row no-gutters>
                <b-col cols="6" class="pr-2">
                  <b-form-group
                    id="input-group-start"
                    class="filter-date"
                    label="From"
                    label-for="input-start"
                  >
                    <datetime
                      input-id="start-date"
                      placeholder="วัน/เดือน/ปี"
                      v-model="filter.start"
                      format="dd/MM/yyyy HH:mm"
                      class="theme-pink"
                      input-class="form-control"
                      type="datetime"
                    >
                    </datetime>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="pl-2">
                  <b-form-group
                    id="input-group-end"
                    class="filter-date"
                    label="To"
                    label-for="input-end"
                  >
                    <datetime
                      input-id="end-date"
                      placeholder="วัน/เดือน/ปี"
                      v-model="filter.end"
                      format="dd/MM/yyyy HH:mm"
                      class="theme-pink"
                      input-class="form-control"
                      type="datetime"
                    >
                    </datetime>
                  </b-form-group>
                </b-col>
              </b-row>
              <span
                class="error-datetime text-error"
                v-if="
                  !$v.filter.start.maxDateTime || !$v.filter.end.minDateTime
                "
              >
                *วันและเวลาเริ่มต้นต้องน้อยกว่าวันสิ้นสุด
              </span>
            </div>
            <InputText
              v-model="filter.search"
              textFloat="Search"
              placeholder="Search Chat History"
              type="text"
              icon="search"
              @submit="handleSearch"
              @onkeyup="handleKeyup"
            />
            <b-button variant="search" class="w-100" @click="handleSearch">
              ค้นหา
            </b-button>
          </div>
          <div class="chat-search-result">
            <div class="title">
              <span class="title-side">Chat Search Result</span>
              <span v-if="total" class="text-total"
                >Found {{ total | numeral("0,0") }} Messages</span
              >
            </div>
            <div class="results">
              <div
                v-for="(item, index) in chatList"
                :key="index + 'history'"
                class="msg-row"
                @click="selectSearchMsg(item, index)"
              >
                <div class="d-flex justify-content-between ">
                  <p class="name">{{ item.senderName }}</p>
                  <ChatDateTime class="time" :date="item.sendTime" />
                </div>
                <div
                  :class="[
                    item.messageType == 4 || item.messageType == 2
                      ? 'msg_cotainer_img'
                      : 'msg_cotainer'
                  ]"
                >
                  <span
                    v-if="
                      item.messageType == 1 ||
                        item.messageType == 21 ||
                        item.messageType == 16 ||
                        item.messageType == 22
                    "
                    class="text-nextline"
                    v-html="item.message"
                  ></span>
                  <div v-if="item.messageType == 4" class="img_upload">
                    <div
                      @click="openModalImage(item.filePath)"
                      class="img_upload-display"
                      v-bind:style="{
                        backgroundImage: 'url(' + item.filePath + ')'
                      }"
                    ></div>
                  </div>
                  <!-- Other Type -->
                  <!-- messageType = 21 postback -->
                  <div
                    v-if="
                      item.messageType != 1 &&
                        item.messageType != 4 &&
                        item.messageType != 21 &&
                        item.messageType != 16 &&
                        item.messageType != 22
                    "
                  >
                    <ChatBoxWidget :con="item" />
                  </div>
                </div>
                <div class="border-bottom-custom"></div>
              </div>
              <infinite-loading
                v-if="chatList.length > 0"
                spinner="spiral"
                @infinite="scrollMethod"
                ref="infiniteLoadingHistory"
              >
                <div slot="spinner" role="status">
                  <img
                    src="@/assets/images/loading-d-dots.gif"
                    alt="loading"
                    class="loading-icon"
                  />
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
              <div
                class="loading-prepare"
                v-if="isLoading && !isLoadingInfinite"
              >
                <img
                  src="@/assets/images/loading-d-dots.gif"
                  alt="loading"
                  class="loading-icon"
                />
              </div>
              <div v-if="chatList.length < 1 && !isLoading" class="reload-chat">
                <div class="text-center">
                  <p>ไม่พบรายการแชท</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
    <ModalImportFile ref="modalImportFile" @uploadFile="uploadFile" />
    <ChatBoxModalImage ref="ChatBoxModalImage" :path="path" />
  </div>
</template>

<script>
import ModalImportFile from "@/components/chat/ModalImportFile";
import InputText from "@/components/inputs/InputText";
import ChatDateTime from "@/components/chat/ChatDateTime";
import ChatBoxWidget from "@/components/chat/chatBox/ChatBoxWidget";
import ChatBoxModalImage from "@/components/chat/chatBox/modal/ChatBoxModalImage";
import { Datetime } from "vue-datetime";
import * as moment from "moment/moment";
const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) > new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) > new Date(value);
};
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    chatRoomId: {
      required: true,
      type: String
    },
    userId: {
      required: true,
      type: String
    }
  },
  components: {
    datetime: Datetime,
    ModalImportFile,
    ChatDateTime,
    InputText,
    ChatBoxWidget,
    ChatBoxModalImage
  },
  validations() {
    return {
      filter: {
        start: {
          required,
          maxDateTime(val, { end }) {
            return maxDate(val, end);
          }
        },
        end: {
          required,
          minDateTime(val, { start }) {
            return minDate(val, start);
          }
        }
      }
    };
  },
  data() {
    return {
      modalMessage: "",
      variant: "dark",
      isShow: false,
      filter: {
        start: moment()
          .subtract(7, "days")
          .format(),
        end: moment().format(),
        search: "",
        length: 20,
        chatroomId: this.chatRoomId,
        messageId: ""
      },
      chatList: [],
      isLoading: false,
      isLoadingInfinite: false,
      importType: "",
      timer: null,
      total: 0,
      isSubmitted: false,
      path: ""
    };
  },
  watch: {
    "filter.start"(newValue, oldValue) {
      if (this.isSubmitted && newValue != oldValue) {
        this.chatList = [];
        this.total = 0;
        this.submitSearch();
      }
    },
    "filter.end"(newValue, oldValue) {
      if (this.isSubmitted && newValue != oldValue) {
        this.chatList = [];
        this.total = 0;
        this.submitSearch();
      }
    }
  },
  methods: {
    hide() {
      this.isSubmitted = false;
      this.isShow = false;
    },
    async show() {
      await this.init();
      this.isShow = true;
    },
    importFileLine() {
      this.importType = "line";
      this.$refs.modalImportFile.show();
    },
    importFile() {
      this.importType = "all";
      this.$refs.modalImportFile.show();
    },
    uploadFile(item) {
      this.modalMessage = "";
      this.$store.dispatch("setIsLoading", true);
      let body = {
        chatroomId: this.chatRoomId,
        userId: this.userId,
        file: item.File
      };
      let url = "ChatHistory";
      // if (this.importType == "all") url = "AllChatHistory";
      fetch(`${this.$chatBaseUrl}/message/Import/${url}`, {
        method: "post",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          this.$store.dispatch("setIsLoading", false);
          this.$refs.modalImportFile.hide();
          if (result.result == 1) {
            this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            this.$emit("reloadChat");
            this.hide();
          } else {
            this.$store.commit("setMessageGlobalAlertError", result.message);
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.$refs.modalImportFile.hide();
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
          console.log("error", error);
          this.isError = true;
        });
    },
    async handleSearch() {
      this.$v.filter.$reset();

      if (this.$refs.infiniteLoadingHistory) {
        this.$refs.infiniteLoadingHistory.stateChanger.reset();
      }
      this.filter.messageId = "";
      this.chatList = [];
      this.total = 0;
      await this.submitSearch();
    },
    handleKeyup() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.chatList = [];
        this.total = 0;
        this.submitSearch();
      }, 2000);
    },
    init() {
      this.filter = {
        start: moment()
          .subtract(7, "days")
          .format(),
        end: moment().format(),
        search: "",
        length: 20,
        chatroomId: this.chatRoomId,
        messageId: ""
      };
      this.chatList = [];
      this.total = 0;
    },
    submitSearch(scrollFlag = false, $state = false) {
      this.isLoading = true;
      this.isSubmitted = true;
      if (this.chatList.length > 0) {
        this.filter.messageId = this.chatList[
          this.chatList.length - 1
        ].messageId;
      } else {
        this.filter.messageId = "";
      }
      let body = { ...this.filter };
      fetch(`${this.$chatBaseUrl}/message/GetChatHistory`, {
        method: "post",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          this.isLoading = false;
          this.isLoadingInfinite = false;
          if (result.result == 1) {
            let messageLogs = result.detail.messageLogs;
            if (scrollFlag) {
              if (messageLogs.length == 0) {
                if ($state) $state.complete();
              } else {
                let list = this.handleMarkText(messageLogs, this.filter.search);
                this.chatList = this.chatList.concat(list);
                this.total = result.detail.foundTotal;
                if ($state) $state.loaded();
              }
            } else {
              let list = this.handleMarkText(messageLogs, this.filter.search);
              this.chatList = this.chatList.concat(list);
              this.total = result.detail.foundTotal;
              if ($state) $state.loaded();
            }
          } else {
            if ($state) $state.complete();
          }
        })
        .catch(error => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    },
    handleMarkText(items, search) {
      let list = items.map(item => {
        let message = item.message.split(search);
        let messageHtml = "";
        if (search) {
          message.forEach((item2, index) => {
            messageHtml += `${item2}${
              index < message.length - 1 ? `<mark>${search}</mark>` : ""
            }`;
          });
        } else messageHtml = item.message;
        return {
          ...item,
          messageHtml: messageHtml
        };
      });

      return list;
    },
    scrollMethod($state) {
      setTimeout(async () => {
        if (this.chatList.length > 0) {
          this.filter.messageId = this.chatList[
            this.chatList.length - 1
          ].messageId;
        }
        this.isLoadingInfinite = true;
        this.submitSearch(true, $state);
      }, 500);
    },
    selectSearchMsg(item, index) {
      if (this.filter.search) {
        this.$emit("selectSearchMsg", item, this.total, index, this.filter);
      }
    },
    openModalImage(path) {
      this.path = path;
      this.$refs.ChatBoxModalImage.show();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep #sidebar-filter-history {
  background-color: rgba(70, 70, 70, 0.5) !important;
}
::v-deep .btn-search {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
::v-deep .btn-import {
  background-color: #fff !important;
  border-color: #832abf;
  color: #832abf;
  border-radius: 33px;
  padding: 2px 7px;
  margin-top: 5px;
  font-size: 12px;
  .bg-icon {
    color: #832abf;
  }
}
.warp-history {
  position: fixed;
  top: 0;
  right: 0;
  height: 100dvh;
  z-index: 1040;
  overflow-x: hidden;
  width: 0%;
  .sidepanel {
    position: relative;
    right: -400px;
    width: 400px;
    height: 100dvh;
    background-color: rgb(255, 255, 255);
    color: #707070;
    border-left: 1px solid #b3afaf6e;
    margin-left: auto;
    transition: 0.5s;
    .closebtn {
      padding: 8px 0 8px 8px;
      text-decoration: none;
      color: #707070;
      display: block;
      transition: 0.3s;
      font-size: 23px;
    }
    .btn-clear {
      text-decoration: none;
      color: #707070;
      display: block;
      transition: 0.3s;
      font-size: 23px;
    }
    .title-side {
      font-size: 16px;
    }
    .text-total {
      color: #9c9c9c;
      font-size: 12px;
    }
    .filter-form {
      min-height: calc(100dvh - 60px);
    }
    .filter-date {
      margin-bottom: 15px;
      label {
        color: #575757;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
    .icon-search {
      position: absolute;
      top: 7px;
      right: 15px;
      color: #768192;
    }
    .chat-search-result {
      background-color: #f6f6f6;
      margin-top: 0.5rem;
      .title {
        background-color: #fff;
        display: flex !important;
        display: -ms-flexbox !important;
        justify-content: space-between !important;
        -ms-flex-pack: justify !important;
        align-items: center !important;
        -ms-flex-align: center !important;
        padding: 0 1rem 0.5rem 1rem;
      }
      .results {
        height: calc(100dvh - 334px + 61px - 14px);
        overflow: hidden;
        overflow-y: auto;
        padding-top: 1rem;
      }
      .results::-webkit-scrollbar {
        width: 0.35em;
      }

      .results::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(177, 175, 175, 0.3);
      }

      .results::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        outline: 1px solid #c1c1c1;
      }
      .msg-row {
        padding: 0 1rem;
        .border-bottom-custom {
          border-bottom: 1px solid #d8dbe0 !important;
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;
        }
        .name {
          margin-bottom: 0;
          font-size: 14px;
          color: #000000;
          font-weight: 600;
        }
        .time {
          color: #bfbfbf;
          font-size: 14px;
        }
      }
    }
  }
  .reload-chat,
  .loading-prepare {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
.warp-history.show {
  width: 100%;
  .sidepanel {
    right: 0;
  }
}
::v-deep mark {
  background-color: #ffc107 !important;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #e9e9e9;
  opacity: 1;
  border: none;
  color: #000;
  overflow-y: hidden !important;
}
::v-deep .dropdown-import-chat {
  ul {
    padding: 0;
  }
  .dropdown-toggle::after {
    margin-left: 5px;
  }
}
::v-deep .input-with-btn-icon {
  .custom-input {
    border-radius: 0.25rem 0px 0px 0.25rem !important;
    border-right: 0 !important;
  }
  .custom-input:focus {
    border-color: #dbdbdb;
  }
  .btn-icon {
    border-radius: 0px 0.25rem 0.25rem 0px;
  }
  .btn-icon:hover,
  .btn-icon:active {
    background-color: transparent;
  }
}
.error-datetime {
  color: #e55353;
  font-size: 11px;
  position: absolute;
  bottom: -2px;
}
.sticky-top {
  z-index: 500;
}

.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  font-size: 14px;
}
.msg_cotainer_img {
  margin-top: auto;
  margin-bottom: auto;
  // margin-left: auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 50%;
  div {
    display: flex;
    width: 100%;
    flex-flow: column;
  }
  .img_upload {
    margin-right: auto;
    height: auto;
    max-height: 20rem;
    width: 100%;
    max-width: 30rem;
    .img_upload-display {
      width: 100%;
      padding-bottom: 56.25%;
      background-size: contain;
      background-position: top left;
      background-repeat: no-repeat;
      background-color: inherit !important;
    }
  }
  .msg_time {
    padding-left: 10px;
  }
}
@media (max-width: 767.98px) {
  .warp-history {
    .sidepanel {
      width: 100%;
      right: -100%;
      .chat-search-result {
        .results {
          height: calc(100dvh - 324px + 51px - 15px);
        }
      }
    }
  }
  .warp-history.show {
    .sidepanel {
      right: 0;
    }
  }
}
</style>
